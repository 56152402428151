(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('ImportRouteCautionDialogController', ImportRouteCautionDialogController);

    ImportRouteCautionDialogController.$inject = ['$uibModalInstance', 'project', 'depot', 'totalItems', 'invalidData', 'validData', 'FileSaver', 'Route'];

    function ImportRouteCautionDialogController($uibModalInstance, project, depot, totalItems, invalidData, validData, FileSaver, Route) {
        var vm = this;
        vm.isSaving = false;
        vm.isDownloaded = false;

        vm.project = project;
        vm.depot = depot;
        vm.totalItems = totalItems;
        vm.invalidData = invalidData;
        vm.validData = validData;

        vm.close = close;
        vm.importRoutePartial = importRoutePartial;
        vm.downloadInvalidData = downloadInvalidData;

        function close() {
            $uibModalInstance.dismiss();
        }

        function importRoutePartial() {
            vm.isSaving = true;

            if (vm.invalidData && vm.invalidData.length) {
                // downloadInvalidData();
            }

            var request = {
                projectId: vm.project.id,
                depotId: vm.depot.id,
                partialRoutes: []
            };

            vm.validData.forEach(function(td){
                td.visits.forEach(function(vs) {
                    var route = {
                        outletId: td.stop.util.selected.id,
                        territory: td.territory.util.selected.id,
                        week: vs.week.data,
                        day: vs.day.data,
                        seq: vs.sequence.data
                    };

                    request.partialRoutes.push(route);
                });
            });

            Route.partialImport(request).$promise.then(
                function(){
                    vm.isSaving = false;
                    $uibModalInstance.close();
                },
                function(){
                    vm.isSaving = false;
                }
            );
        }

        function downloadInvalidData() {
            if (vm.isDownloaded || !vm.invalidData.length) {
                return;
            }
            var invalidData = convertInvalidData();
            var csv = Papa.unparse(invalidData);
            var file = new Blob([csv], {type: 'text/csv'});
            FileSaver.saveAs(file, 'invalid-route.csv');
            vm.isDownloaded = true;
        }

        function convertInvalidData() {
            var result = [];

            vm.invalidData.forEach(function (invalid) {
                if (invalid.visits && invalid.visits.length) {
                    invalid.visits.forEach(function (visit) {
                        var withVisit = {
                            external_id: invalid.stop.data,
                            territory: invalid.territory.data,
                            week: visit.week.data,
                            day: visit.day.data,
                        };

                        withVisit.unacceptable_reason = [];
                        withVisit.unacceptable_reason = createUnacceptableReason(invalid, visit);

                        result.push(withVisit);
                    });
                } else {
                    var withoutVisit = {
                        external_id: invalid.stop.data,
                        territory: invalid.territory.data,
                        week: '',
                        day: '',
                        sequence: '',
                    };

                    withoutVisit.unacceptable_reason = [];
                    withoutVisit.unacceptable_reason = createUnacceptableReason(invalid);

                    result.push(withoutVisit);
                }
            });

            return result;
        }

        function createUnacceptableReason(data, visit) {
            var reasons = [];

            // error stop
            if (data.stop.util.error) {
                if (data.stop.util.error.required) {
                    reasons.push('Stop: Stop External Id is required');
                }
                if (data.stop.util.error.notRegistered) {
                    reasons.push('Stop: Stop is not registered');
                }
                if (data.stop.util.error.duplicate){
                    reasons.push('Stop: Stop is duplicate');
                }
            }

            // error territory
            if (data.territory.util.error) {
                if (data.territory.util.error.required) {
                    reasons.push('Territory: Territory is required');
                }
                if (data.territory.util.error.notRegistered) {
                    reasons.push('Territory: Territory is not registered');
                }
            }

            // error week
            if (visit && visit.week.error) {
                if (visit.week.error.required) {
                    reasons.push('Week: Week is required');
                }
                if (visit.week.error.notNumber) {
                    reasons.push('Week: Week is not a number');
                }
                if (visit.week.error.outOfRange) {
                    reasons.push('Week: Week is out of range');
                }
                if (visit.week.error.excessiveRoute) {
                    reasons.push('Visit: Excessive visits');
                }
                if (visit.week.error.duplicateRoute) {
                    reasons.push('Visit: Duplicate visit');
                }
                if (visit.week.patternError) {
                    reasons.push('Week: Wrong week pattern');
                }
            }

            // error day
            if (visit && visit.day.error) {
                if (visit.day.error.required) {
                    reasons.push('Day: Day is required');
                }
                if (visit.day.error.notNumber) {
                    reasons.push('Day: Day is not a number');
                }
                if (visit.day.error.outOfRange) {
                    reasons.push('Day: Day is out of range');
                }
                if (visit.week.patternError) {
                    reasons.push('Day: Wrong day pattern');
                }
            }

            // error sequence
            if (visit && visit.sequence.error) {
                if (visit.sequence.error.notNumber) {
                    reasons.push('Sequnce: Sequnce is not a number');
                }
            }

            return reasons.join(", ");
        }

    }
})();
