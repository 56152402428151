(function () {
    "use strict";

    angular
        .module("stpApp")
        .controller("RoutingsController", RoutingsController);

    RoutingsController.$inject = ["$stateParams", "$scope", "Route", "$q", "projectResource", "OsrmAddress", "uiSortableMultiSelectionMethods", "avoidTollsFeatureToggleVal",
        "mapPropertyResource", "FormatterUtil", "TileLayerAsyncService", "$state", "ngToast", "depotResource", "DailyRouteService", "$localStorage", "transitList",
        "TransitPoint", "currentTransitPoint", "$timeout", "$translate", "isOneTimeUser", "RoutePlanningModalFactory",
        "HTTP_CODE_CONCURRENT_USER_ID_MISMATCH"];

    function RoutingsController($stateParams, $scope, Route, $q, projectResource, OsrmAddress, uiSortableMultiSelectionMethods, avoidTollsFeatureToggleVal,
        mapPropertyResource, FormatterUtil, TileLayerAsyncService, $state, ngToast, depotResource, DailyRouteService, $localStorage, transitList,
        TransitPoint, currentTransitPoint, $timeout, $translate, isOneTimeUser, RoutePlanningModalFactory,
        HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
        var vm = this;

        // services and data resolves
        vm.format = FormatterUtil;
        vm.project = projectResource;
        vm.mapProperties = mapPropertyResource[0].style;
        vm.tileLayer = TileLayerAsyncService;
        vm.depot = depotResource;
        vm.transits = transitList;
        vm.isOneTimeUser = isOneTimeUser;

        vm.isLoadingTable = false;

        // leaflet control
        var zoom = new L.Control.Zoom({ position: "topright" });

        // controller properties
        vm.map = L.map("map", {
            renderer: L.canvas(),
            touchExtend: false,
            doubleClickZoom: false,
            zoomControl: false
        });
        vm.isSummaryOpen = true;
        vm.sortableOptions = uiSortableMultiSelectionMethods.extendOptions({
            "ui-floating": false,
            "ui-preserve-size": true,
            axis: "y",
            stop: onDragStop,
            scrollSensitivity: 100, scrollSpeed: 20,
            handle:'.grab-me',
            placeholder:'ui-sortable-placeholder',
            tolerance: 'pointer',
        });

        vm.option = {
            territory: $stateParams.territory ? $stateParams.territory : 1,
            week: $stateParams.week ? $stateParams.week : 1,
            day: $stateParams.day ? $stateParams.day : 1,
            capacity: vm.mapProperties.depots[vm.depot.id].territories[$stateParams.territory ? $stateParams.territory : 1].loadCapacity ? vm.mapProperties.depots[vm.depot.id].territories[$stateParams.territory ? $stateParams.territory : 1].loadCapacity : 0
        };
        vm.isEditTransitPoint = false;
        vm.showStartPointDialog = true;
        vm.tripCount = 0;

        // load Tilelayer
        var layerControl
        TileLayerAsyncService.getBaseMaps().then(onLoadBaseMapSuccess, onLoadBaseMapError);

        function onLoadBaseMapSuccess(baseMaps){
            setLayerControl(baseMaps);
        }

        function onLoadBaseMapError(defaultBasemap){
            setLayerControl(defaultBasemap);
        }

        function setLayerControl(baseMaps) {
            var basemapskeys = Object.keys(baseMaps);
            var defaultBaseMap;

            if (basemapskeys.length) {
                var isLocalBasemapExist = basemapskeys.find(function(key){
                    return key === $localStorage.selectedBasemap;
                });

                if (isLocalBasemapExist) {
                    defaultBaseMap = baseMaps[$localStorage.selectedBasemap];
                } else {
                    defaultBaseMap = baseMaps[basemapskeys[0]];
                    $localStorage.selectedBasemap = basemapskeys[0];
                }

                if (defaultBaseMap) {
                    defaultBaseMap.addTo(vm.map);
                }

                layerControl = L.control.layers(baseMaps, null, { position: "bottomright" }).addTo(vm.map);
            }
        }
        vm.dailyRoutesData = [];

        // ui action
        vm.apply = apply;
        vm.autoRoute = autoRoute;
        vm.showRoute = showRoute;
        vm.back = back;
        vm.onChange = onChange;
        vm.setTransitPointToRoute = setTransitPointToRoute;
        vm.showEditTransitPoint = showEditTransitPoint;
        vm.onStartPointChange = onStartPointChange;
        vm.onFinishPointChange = onFinishPointChange;
        vm.saveNewTransitPoint = saveNewTransitPoint;
        vm.onNewTransitSelected = onNewTransitSelected;
        vm.openUnassignDialog = openUnassignDialog;

        // initial map action
        zoom.addTo(vm.map);
        vm.map.setView([-2.49607, 117.89558], 5);

        // initial controller action
        init();

        // map event
        vm.map.on('baselayerchange', function (e) {
            $localStorage.selectedBasemap = e.layer.options.tileLayerName;
        });

        function onChange() {
            vm.disableOnLoad = true;
        }

        function init() {
            vm.disableOnLoad = true;
            vm.disableApplyOnLoad = true;
            vm.invalidArrivalTime = false;
            vm.isLoadingTable = true;
            vm.isLoadingSummary = true;
            vm.durationToDepot = "0";

            loadStartAndFinishPoint();
            resetMap();

            Route.query(
                {
                    "territory.equals": vm.option.territory,
                    "week.equals": vm.option.week,
                    "day.equals": vm.option.day,
                    "projectId.equals": vm.project.id,
                    "depotId.equals": vm.depot.id,
                    sort: "seq,asc",
                    size: 2000,
                },
                onLoadRouteSuccess,
                onLoadRouteError
            );

            function onLoadRouteSuccess(routesData) {
                ngToast.dismiss(vm.loadToast);

                var isSomeDataNull = routesData.some(function (route) {
                    return route.seq == null;
                });

                if (isSomeDataNull) {
                    Route.resetRouteSeq(
                        {
                            projectId: vm.project.id,
                            territory: vm.option.territory,
                            week: vm.option.week,
                            day: vm.option.day,
                            depot: vm.depot.id,
                        },
                        function () {
                            loadSummary();
                        }
                    );
                } else {
                    loadSummary();
                }
            }

            function onLoadRouteError(error) {
                $("#wayptserr").css("display", "none");
                $("#backToDepotEl").css("display", "none");

                vm.noError = false;
                ngToast.dismiss(vm.loadToast);
                if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                    ngToast.danger({content:"<strong>" +error.status +" : " +error.data.detail +"</strong>",dismissButton: true,timeout: 5000});
                }
            }
        }

        function drawRoute(waypoints) {
            if (!vm.routingControl) {
                var osrmOptions = {
                    serviceUrl: vm.osrmAddress + "/route/v1",
                    routingOptions: { allowUTurns: true },
                    language: "en",
                }
                if(vm.project.props.avoidTolls && avoidTollsFeatureToggleVal) {
                    osrmOptions.requestParameters = {exclude: 'motorway'}
                }
                vm.routingControl = L.Routing.control({
                    router: new L.Routing.osrmv1(osrmOptions),
                    createMarker: function (i, start, n) {
                        return markerBuild(i, start, n);
                    },
                    lineOptions: {
                        styles: [
                            {
                                color: "#058DD9",
                            },
                        ],
                    },
                    addWaypoints: false,
                    draggableWaypoints: false,
                    routeWhileDragging: false,
                    fitSelectedRoutes: true,
                    autoRoute: true
                }).addTo(vm.map);
            }

            vm.routingControl.setWaypoints(waypoints);

            $(".leaflet-routing-container").css("display", "none");
            vm.disableApplyOnLoad = false;
            vm.disableOnLoad = false;
        }

        function createWayPointsLatLngs(start, dailyRoutes, finish) {
            var routeLatLngs = dailyRoutes.map(function (dailyRoute) {
                return L.latLng(
                    dailyRoute.stopLatitude,
                    dailyRoute.stopLongitude
                );
            });

            var waypointLatLngs = [];
            var startLatLngs = L.latLng(start.lat, start.lon);
            var finishLatLngs = L.latLng(finish.lat, finish.lon);

            if (routeLatLngs.length) {
                waypointLatLngs = waypointLatLngs.concat(routeLatLngs);
                waypointLatLngs.unshift(startLatLngs);
                waypointLatLngs.push(finishLatLngs);
            }

            return waypointLatLngs;
        }

        function markerBuild(i, start, n) {
            var marker_icon = null;
            if (i === 0) {
                marker_icon = L.divIcon({
                    className: "map-marker",
                    iconSize: [25, 25],
                    html: '<img src="content/images/start-point-marker.png" style="width:25px;height:25px"></img>',
                });
            } else if (i < n - 1) {
                marker_icon = L.divIcon({
                    className: "icon-route-waypoint",
                    iconSize: [25, 25],
                    html: "<b>" + (i) + "</b>",
                });
            } else {
                marker_icon = L.divIcon({
                    className: "map-marker",
                    iconSize: [25, 25],
                    html: '<img src="content/images/finish-point-marker.png" style="width:25px;height:25px"></img>',
                });
            }
            var marker = L.marker(start.latLng, {
                draggable: false,
                bounceOnAdd: false,
                icon: marker_icon,
            });
            return marker;
        }

        function apply() {
            var params = {
                territory: vm.option.territory,
                week: vm.option.week,
                day: vm.option.day,
            };

            $state.go("route-plan.routings", params, { notify: true });
        }

        function autoRoute() {
            vm.disableOnLoad = true;
            vm.disableApplyOnLoad = true;
            vm.isSetShortestInProgress = true;
            var confirmationText = confirm("This operation cannot be undone. Would you like to proceed?");

            if (confirmationText) {
                mixpanel.time_event('Set Shortest Trip Successful')
                var bodyRequest = {
                    projectId: $stateParams.id,
                    territory: $stateParams.territory,
                    week: $stateParams.week,
                    day: $stateParams.day,
                    depot: $stateParams.depot,
                }
                Route.resetRouteSeq(bodyRequest, _onSuccess, _onError);
            } else {
                vm.disableOnLoad = false;
                vm.disableApplyOnLoad = false;
                vm.isSetShortestInProgress = false;
            }

            function _onSuccess() {
                vm.isSetShortestInProgress = false;
                mixpanel.track('Set Shortest Trip Successful', {
                    'isAllRoutes': false,
                    'stopCount': vm.dailyRoutesData.length
                });
                init();
            }

            function _onError() {
                vm.isSetShortestInProgress = false;
            }
        }

        function showRoute(dailyRoute) {
            var lat = dailyRoute.stopLatitude ? dailyRoute.stopLatitude : dailyRoute.lat;
            var lon = dailyRoute.stopLongitude ? dailyRoute.stopLongitude : dailyRoute.lon;
            var latLng = [lat, lon];
            vm.map.setView(latLng, 19);
        }

        function back() {
            var params = {
                territory: null,
                week: null,
                day: null,
            };
            $state.go("^", params);
        }

        function loadSummary() {
            vm.isLoadingSummary = true;
            vm.loadToast = ngToast.info({
                content: "<strong>Please wait ... </strong>",
                dismissButton: false,
                dismissOnTimeout: false,
            });

            var params = {
                projectId: vm.project.id,
                territory: vm.option.territory,
                week: vm.option.week,
                day: vm.option.day,
                depotId: vm.depot.id,
                refresh: !vm.project.props.useCapacity ? true : false
            };

            var promiseAfter = DailyRouteService.getDailySummaryAfter(params).$promise;
            var promiseBefore = DailyRouteService.getDailySummaryBefore(params).$promise;
            var promises = [promiseBefore, promiseAfter];

            $q.all(promises).then(onLoadSummarySuccess, onLoadSummaryError);

            function onLoadSummarySuccess(data) {
                vm.isLoadingSummary = false;
                ngToast.dismiss(vm.loadToast);

                var before = data[0],
                    after = data[1];

                var finishTimeDiff = moment.duration(Math.abs(before.finishTime - after.finishTime),"seconds"),
                    overtimeDurationDiff = Math.abs(before.overtimeDuration - after.overtimeDuration),
                    workingHourDurationDiff = Math.abs(before.workingHourDuration - after.workingHourDuration),
                    travelDurationDiff = Math.abs(before.travelDuration - after.travelDuration),
                    totalDistanceDiff = Math.abs(before.travelDuration - after.travelDuration);

                function _keyIsNull(idx) {
                    return idx === null;
                }

                function _createBeforeValueArray(key) {
                    return before[key];
                }

                function _filterObjectKey(key) {
                    return key !== "$promise" && key !== "$resolved";
                }

                function _createAfterValueArray(key) {
                    return after[key];
                }

                vm.isNoBeforeSummary = Object.keys(before).filter(_filterObjectKey).map(_createBeforeValueArray).every(_keyIsNull);

                vm.isNoAfterSummary = Object.keys(after).filter(_filterObjectKey).map(_createAfterValueArray).every(_keyIsNull);

                vm.summaryBefore = {
                    finishTime: moment.unix(before.finishTime).utc().format("HH:mm:ss"),
                    overtimeDuration: vm.format.humanizeDurationFormat(before.overtimeDuration),
                    workingHourDuration: vm.format.humanizeDurationFormat(before.workingHourDuration),
                    travelDuration: vm.format.humanizeDurationFormat(before.travelDuration),
                    totalDistance: vm.format.humanizeDistanceFormat(before.totalDistance),
                };

                vm.summaryAfter = {
                    finishTime: moment.unix(after.finishTime).utc().format("HH:mm:ss"),
                    overtimeDuration: vm.format.humanizeDurationFormat(after.overtimeDuration),
                    workingHourDuration: vm.format.humanizeDurationFormat(after.workingHourDuration),
                    travelDuration: vm.format.humanizeDurationFormat(after.travelDuration),
                    totalDistanceHumanize: vm.format.humanizeDistanceFormat(after.totalDistance),
                    totalDistance: after.totalDistance
                };

                vm.summaryDiff = {
                    finishTime: vm.format.humanizeDurationFormat(finishTimeDiff),
                    overtimeDuration: vm.format.humanizeDurationFormat(overtimeDurationDiff),
                    workingHourDuration: vm.format.humanizeDurationFormat(workingHourDurationDiff),
                    travelDuration: vm.format.humanizeDurationFormat(travelDurationDiff),
                    totalDistance: vm.format.humanizeDistanceFormat(totalDistanceDiff),
                };

                vm.percent = {
                    finishTime: diffPercentage(before.finishTime, after.finishTime),
                    overtimeDuration: diffPercentage(before.overtimeDuration, after.overtimeDuration),
                    workingHourDuration: diffPercentage(before.workingHourDuration, after.workingHourDuration),
                    travelDuration: diffPercentage(before.travelDuration, after.travelDuration),
                    totalDistance: diffPercentage(before.totalDistance, after.totalDistance),
                };

                vm.invalidArrivalTime = after.overtimeDuration > 0 ? true : false;

                loadDailyRoute();
            }

            function onLoadSummaryError(error) {
                vm.isLoadingSummary = false;
                ngToast.dismiss(vm.loadToast);
            }
        }

        function loadDailyRoute() {
            var pathParams = { projectId: vm.project.id, refresh: vm.project.props.useCapacity ? false : true };
            var bodyRequest = {
                depotId: vm.depot.id,
                territory: vm.option.territory,
                day: vm.option.day,
                week: vm.option.week,
            };
            var projectTimeWindows = vm.project.props.dayTimeWindows;
            var workHour =  projectTimeWindows ? projectTimeWindows.find(findWorkHour): null;
            var startTimeEpochSeconds = workHour && workHour.startTime ? workHour.startTime : 0;
            var endTimeEpochSeconds = workHour && workHour.endTime ? workHour.endTime : 86340;

            DailyRouteService.getPerDayDetails(pathParams, bodyRequest, onLoadDailyRouteSuccess, onLoadDailyRouteError);

            function isValidTimeWindow(route) {
                if (!route.stopTimeWindowStart && !route.stopTimeWindowEnd) {
                    return true
                }

                // Parse the arrivalTime string and convert it to seconds
                var parsedArrivalTime = moment(route.arrivalTime, "HH:mm:ss");
                var arrivalTimeInSeconds = parsedArrivalTime.diff(parsedArrivalTime.clone().startOf('day'), 'seconds');

                // Check if arrivalTime is within the time window
                if (arrivalTimeInSeconds >= route.stopTimeWindowStart && arrivalTimeInSeconds <= route.stopTimeWindowEnd) {
                    return true;
                } else {
                    return false;
                }
            }

            function onLoadDailyRouteSuccess(dailyRoutesData) {
                vm.isLoadingTable = false;
                dailyRoutesData.forEach(function (item) {
                    item.stopTimeWindowStartStr = item.stopTimeWindowStart ? moment.unix(item.stopTimeWindowStart).utc().format('HH:mm') : null;
                    item.stopTimeWindowEndStr = item.stopTimeWindowEnd ? moment.unix(item.stopTimeWindowEnd).utc().format('HH:mm') : null;
                    item.isValidTimeWindow = isValidTimeWindow(item);
                });

                var reloads = dailyRoutesData.filter(function(d){
                    return d.reloading;
                });

                vm.tripCount = reloads.length + 1;

                var accumulationTimeInStop = 0 + startTimeEpochSeconds;

                vm.dailyRoutesData = dailyRoutesData;

                vm.totalDailyRouteTravelKm = dailyRoutesData.reduce(function(sum, each){
                    sum += each.travelKm;
                    return sum;
                },0);

                vm.dailyRoutesData.forEach(function (dailyRoute, index, arr) {
                    var stopDurationInSeconds = moment.duration(dailyRoute.stopDuration).asSeconds();
                    var travelTimeInSeconds = moment.duration(dailyRoute.travelTime).asSeconds();

                    accumulationTimeInStop += stopDurationInSeconds + travelTimeInSeconds;
                    dailyRoute.stopDurationHumanize = vm.format.humanizeDurationFormat(stopDurationInSeconds);
                    dailyRoute.travelTimeHumanize = vm.format.humanizeDurationFormat(travelTimeInSeconds);
                    dailyRoute.isOverTime = accumulationTimeInStop > endTimeEpochSeconds ? true : false;
                });

                var finishDateString = "1970-01-01 " + vm.summaryAfter.finishTime;
                var lastArrivalDateString = vm.dailyRoutesData.length ? "1970-01-01 " + vm.dailyRoutesData[vm.dailyRoutesData.length - 1].leaveTime: "1970-01-01 00:00:00";
                var ms = moment(finishDateString, "YYYY-MM-DD HH:mm:ss").diff(moment(lastArrivalDateString, "YYYY-MM-DD HH:mm:ss"));
                var secondDiff = moment.duration(ms).asSeconds();

                vm.durationToDepot = vm.dailyRoutesData.length ? vm.format.humanizeDurationFormat(Math.abs(secondDiff)) : vm.format.humanizeDurationFormat(0);

                OsrmAddress.getAddress().then(onLoadOsrmAddressSuccess, onLoadOsrmAddressError);

                function onLoadOsrmAddressSuccess(address){
                    vm.osrmAddress = address;
                    var waypoints = [];
                    if (vm.startPoint && vm.finishPoint) {
                        waypoints = createWayPointsLatLngs(vm.startPoint, vm.dailyRoutesData, vm.finishPoint);
                    } else {
                        waypoints = createWayPointsLatLngs(vm.depot, vm.dailyRoutesData, vm.depot);
                    }
                    drawRoute(waypoints);
                }

                function onLoadOsrmAddressError(error){
                    if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                        ngToast.danger({
                            content: "<strong>" + error + "</strong>",
                            dismissButton: true,
                            timeout: 5000,
                        });
                    }
                }
            }

            function onLoadDailyRouteError(error) {
                vm.isLoadingTable = false;
                ngToast.dismiss(vm.loadToast);
            }

            function findWorkHour(eachTimeWindow) {
                return eachTimeWindow.day == vm.option.day;
            }
        }

        function diffPercentage(first, second) {
            var a = first != null ? first : 0;
            var b = second != null ? second : 0;
            var percentage = 0;

            if (a == 0 || b == 0) {
                percentage = a > b ? 100 : -100;
            }

            if (a != 0 && b != 0) {
                if (a > b) {
                    percentage = (((a - b) / a) * 100).toFixed(2);
                }

                if (a < b) {
                    percentage = (((b - a) / b) * 100 * -1).toFixed(2);
                }
            }

            if (a == b) {
                percentage = 0;
            }
            return percentage;
        }

        function onDragStop(e, ui) {
            var updateRouteRequestBody = [];

            vm.dailyRoutesData.forEach(function (each, idx) {
                each.sequence = idx + 1;
                updateRouteRequestBody.push({
                    id: each.routeId,
                    seq: each.sequence,
                });
            });

            Route.updateRouteSeq(updateRouteRequestBody, onUpdateRouteSuccess, onUpdateRouteError);

            function onUpdateRouteSuccess(data) {
                loadSummary();
            }

            function onUpdateRouteError(error) {
                loadSummary();
            }
        }

        function isLatLong(value) {
            if(typeof value === 'string') {
                var pattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/g;
                var res = pattern.test(value);
                return res;
            }
            return false
        }

        function setTransitPointToRoute() {
            var pathParams = { projectId: vm.project.id };
            var bodyRequest = {
                day: vm.option.day,
                depotId: vm.depot.id,
                endPointId: vm.finishPoint.id,
                endPointType: vm.finishPoint.pointType,
                startPointId: vm.startPoint.id,
                startPointType: vm.startPoint.pointType,
                territory: vm.option.territory,
                week: vm.option.week,
            }

            TransitPoint.setToRoute(pathParams, bodyRequest, _onSetTransitSuccess, _onsetTransitFailed);

            function _onSetTransitSuccess(data) {
                vm.isEditTransitPoint = false;
                vm.isAddNewStartPoint = false;
                vm.isAddNewFinishPoint = false;
                init();
            }

            function _onsetTransitFailed(error) {
                console.log(error);
            }
        }

        function showEditTransitPoint(flag) {
            vm.isEditTransitPoint = flag;
            if(flag) {
                vm.tempStartPoint = vm.startPoint;
                vm.tempFinishPoint = vm.finishPoint;
            }
            else {
                vm.startPoint = vm.tempStartPoint;
                vm.finishPoint = vm.tempFinishPoint;
                vm.isAddNewStartPoint = false;
                vm.isAddNewFinishPoint = false;
            }
            vm.isCreateStartPoint = isLatLong(vm.startPoint);
            vm.isCreateFinishPoint = isLatLong(vm.finishPoint);
            init();
        }

        function loadStartAndFinishPoint() {
            if(vm.startPoint && vm.finishPoint) {
                return;
            }
            if (currentTransitPoint) {
                vm.startPoint = transitList.find(function(each){
                    return each.id == currentTransitPoint.startPointId && each.pointType == currentTransitPoint.startPointType;
                });
                vm.finishPoint = transitList.find(function(each){
                    return each.id == currentTransitPoint.endPointId && each.pointType == currentTransitPoint.endPointType;
                });
            } else {
                vm.startPoint = depotResource;
                vm.finishPoint = depotResource;
            }
        }

        function resetMap() {
            $timeout(function(){
                vm.map.invalidateSize()
            }, 500);
        }

        function onStartPointChange() {
            vm.isCreateStartPoint = isLatLong(vm.startPoint);
            redrawRouteOnTransitPointChanged();
        }

        function onFinishPointChange() {
            vm.isCreateFinishPoint = isLatLong(vm.finishPoint);
            redrawRouteOnTransitPointChanged();
        }

        function onNewTransitSelected() {
            redrawRouteOnTransitPointChanged();
        }

        function saveNewTransitPoint(key) {
            var latLng = key === 'START' ? vm.startPoint.split(',') : vm.finishPoint.split(',');
            var requestBody = {
                externalId: key === 'START' ? vm.startPointExternalId : vm.finishPointExternalId,
                lat: latLng[0],
                lon: latLng[1],
                name: key === 'START' ? vm.startPointName : vm.finishPointName,
                projectId: vm.project.id,
                props: {},
            };

            var pathParams = { projectId : vm.project.id}

            TransitPoint.create(pathParams, requestBody, _onSaveNewTransitPointSuccess, _onSaveNewTransitPointFailed);

            function _onSaveNewTransitPointSuccess(data) {
                vm.showError = false;
                var newTransitPoint = Object.assign({pointType: 'TRANSIT'}, data);
                vm.transits.push(newTransitPoint);

                if (key === 'START') {
                    vm.isAddNewStartPoint = false;
                    vm.startPoint = vm.transits[vm.transits.length - 1];
                } else {
                    vm.isAddNewFinishPoint = false
                    vm.finishPoint = vm.transits[vm.transits.length - 1];
                }
            }

            function _onSaveNewTransitPointFailed(error) {
                var params = error.data.params;
                var errorKey = error.data.message;
                vm.errorTranslate = $translate.instant('stpApp.routings.transit.error.' + errorKey, params);
                vm.showError = true;
            }
        }

        function redrawRouteOnTransitPointChanged() {
            var currentStartPoint = vm.startPoint.id ? vm.startPoint : isLatLong(vm.startPoint) ? latLngStringToLatLonObj(vm.startPoint) : null;
            var currentFinishPoint = vm.finishPoint.id ? vm.finishPoint : isLatLong(vm.finishPoint) ? latLngStringToLatLonObj(vm.finishPoint) : null;

            if(currentStartPoint && currentFinishPoint) {
                var waypoints = createWayPointsLatLngs(currentStartPoint, vm.dailyRoutesData, currentFinishPoint);
                drawRoute(waypoints);
            }

            function latLngStringToLatLonObj(value) {
                var arrLatLng = value.split(",");
                return {
                    lat: arrLatLng[0],
                    lon: arrLatLng[1]
                }
            }
        }

        function openUnassignDialog (route) {
            var stopIds = route.stopId ? [route.stopId] : [];
            var changeFreqDialog = RoutePlanningModalFactory.unassignStopModal(stopIds, vm.project.id);
            changeFreqDialog.result.then(function () {
                loadSummary();
            });
        }
    }
})();
